import '@fortawesome/fontawesome-svg-core/styles.css'

import { library, config } from '@fortawesome/fontawesome-svg-core'

import { 
  faBars,
  faSearch,
  faArrowLeft,
  faAtom,
  faHeart,
  faHeartBroken,
  faShare,
  faFlag,
  faPlay,
  faTimes,
  faLink,
  faVideo,
  faCaretDown,
  faCaretUp,
  faCaretLeft,
  faCaretRight,
  faListUl,
  faHome,
  faFire,
  faFireAlt,
  faArrowRight,
  faPepperHot,
  faForward
} from '@fortawesome/free-solid-svg-icons'

import {
  faTwitter,
  faTelegramPlane,
  faRedditAlien,
  faVk,
  faOdnoklassniki,
  faWeibo,
  faWhatsapp,
  faDiscord,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons'

config.autoAddCss = false

library.add(
  faBars,
  faSearch,
  faArrowLeft,
  faAtom,
  faHeart,
  faHeartBroken,
  faShare,
  faFlag,
  faPlay,
  faTimes,
  faLink,
  faVideo,
  faCaretDown,
  faCaretUp,
  faCaretLeft,
  faCaretRight,
  faTwitter,
  faTelegramPlane,
  faRedditAlien,
  faVk,
  faOdnoklassniki,
  faWeibo,
  faWhatsapp,
  faDiscord,
  faInstagram,
  faListUl,
  faHome,
  faFire,
  faFireAlt,
  faArrowRight,
  faPepperHot,
  faForward
)