import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import cx from 'classnames'

import Icon from 'components/Icon'

import styles from './Sidebar.module.scss'

const Sidebar = ({ shouldShow, onHamburgerClick, onOverlayClick }) => {
  const data = useStaticQuery(graphql`
    {

      categories: allSloppyDeepCategory(
        filter: {name: {in: ["big tits", "teen", "moaning", "doggy"]}}
        ) {
        nodes {
          name
          slug
        }
      }

      logo: file(
        relativeDirectory: {regex: "/"},
        name: {eq: "logoV3"}
      ) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

    }
  `)

  const { categories, logo } = data

  const friends = [
    { name: "webcam piger", url: "https://www.piger.cam/", aTitle: ""},
    { name: "Lovesense Cam", url: "https://www.lovesense.cam/", aTitle: ""},
    { name: "Sexcam Models", url: "https://sexcam-models.com/", aTitle: ""},
    { name: "Sex Cam Live", url: "https://sex-cam.live/", aTitle: ""},
    { name: "Kinky Florida Amateurs", url: "https://www.kinkyfloridaamateurs.com/", aTitle: ""},
    { name: "Best Porn Gallery", url: "https://fleshlyx.com/", aTitle: "Adult Website Gallery and Adult Job Board - FleshlyX"},
    { name: "SoulCams", url: "https://www.soulcams.com/", aTitle: ""},
    { name: "Hottest Webcam Models", url: "https://hottestcambabes.net/", aTitle: ""},
    { name: "Rudecamreviews", url: "https://rudecamreviews.com/", aTitle: ""},
    { name: "Rudegamers", url: "https://rudegamers.com/", aTitle: ""}
  ]

  return (
    <>
      <div className={cx(styles.sidebarOverlay, { [styles.showSidebarOverlay]: shouldShow })} onClick={onOverlayClick}></div>
      <section className={cx(styles.sidebar, { [styles.showSidebar]: shouldShow })}>
        <header className={styles.header}>
          <button className={styles.hamburger} onClick={onHamburgerClick} aria-label='toggle menu'>
            <Icon icon={["fas", "bars"]} toggleIcon />
          </button>

          <Link to='/'>
            <Img fluid={logo.childImageSharp.fluid} className={styles.logoImg} alt='Sloppy Deep Logo' />
            {false && <img src={''} className={styles.logoImg} alt='Sloppy Deep Logo' />}
          </Link>
        </header>
        <nav className={styles.content}>
          <ul className={styles.block}>
            <li className={styles.listItem}>
              <Link to='/' onClick={onHamburgerClick} className={styles.listItemLink} activeClassName={styles.listItemActive}>
                <Icon className={styles.listItemIcon} icon={["fas", "home"]} />
                <span className={styles.listItemTitle}>Home</span>
              </Link>
            </li>
            <li className={styles.listItem}>
              <a href='https://insta-local-dating.com/?u=t168wwl&o=za3kbgy&t=SD&cid=first' target='_blank' rel='noopener' className={styles.listItemLink}>
                <Icon className={styles.listItemIcon} icon={["fas", "pepper-hot"]} />
                <span className={styles.listItemTitle}>Try Live Dating</span>
              </a>
            </li>
          </ul>
          <ul className={styles.block}>
            <li className={styles.listItem}>
              <Link to='/videos/' onClick={onHamburgerClick} className={styles.listItemLink} activeClassName={styles.listItemActive}>
                <Icon className={styles.listItemIcon} icon={["fas", "video"]} />
                <span className={styles.listItemTitle}>Latest Videos</span>
              </Link>
            </li>
            <li className={styles.listItem}>
              <Link to='/videos/popular/' onClick={onHamburgerClick} className={styles.listItemLink} activeClassName={styles.listItemActive}>
                <Icon className={styles.listItemIcon} icon={["fas", "fire"]} />
                <span className={styles.listItemTitle}>Popular Videos</span>
              </Link>
            </li>
          </ul>
          <ul className={styles.block}>
            <li className={styles.listItem}>
              <Link to='/models/' onClick={onHamburgerClick} className={styles.listItemLink} activeClassName={styles.listItemActive}>
                <Icon className={styles.listItemIcon} icon={['custom', 'webcam']} />
                <span className={styles.listItemTitle}>Latest Models</span>
              </Link>
            </li>
            <li className={styles.listItem}>
              <Link to='/models/popular/' onClick={onHamburgerClick} className={styles.listItemLink} activeClassName={styles.listItemActive}>
                <Icon className={styles.listItemIcon} icon={['fas', 'fire']} />
                <span className={styles.listItemTitle}>Popular Models</span>
              </Link>
            </li>
          </ul>
          <div className={styles.block}>
            <div className={styles.blockTitle}>Categories</div>
            <ul className={styles.blockList}>

              {categories.nodes.map((category, idx) => (
                <li key={idx} className={styles.listItem}>
                  <Link to={`/categories/${category.slug}/`} onClick={onHamburgerClick} className={styles.listItemLink} activeClassName={styles.listItemActive}>
                    <Icon className={styles.listItemIcon} icon={["fas", "list-ul"]} />
                    <span className={styles.listItemTitle}>{category.name}</span>
                  </Link>
                </li>
              ))}

              <li className={styles.listItem}>
                <Link to={`/categories/`} onClick={onHamburgerClick} className={styles.listItemLink} activeClassName={styles.listItemActive}>
                  <Icon className={styles.listItemIcon} icon={["fas", "arrow-right"]} />
                  <span className={styles.listItemTitle}>Show More</span>
                </Link>
              </li>

            </ul>
          </div>
          <div className={cx(styles.block, styles.friendsBlock)}>
            <div className={styles.blockTitle}>Friends</div>
            <ul className={styles.blockList}>

              {friends.map((friend, idx) => (
                <li key={idx} className={styles.listItem}>
                  <a href={friend.url} target='_blank' rel='noopener' className={styles.listItemLink} title={friend.aTitle}>
                    <Icon className={styles.listItemIcon} icon={["fas", "pepper-hot"]} />
                    <span className={styles.listItemTitle}>{friend.name}</span>
                  </a>
                </li>
              ))}

            </ul>
          </div>
          <div className={cx(styles.block, styles.footer)}>
            <div className={styles.socialList}>
              <a className={styles.socialItem} href='https://twitter.com/SloppyDeepCom' aria-label='Twitter' target='_blank' rel='noopener'>
                <Icon className={styles.socialIcon} icon={["fab", "twitter"]} />
              </a>
              <a className={styles.socialItem} href='https://t.me/SloppyDeep' aria-label='Telegram' target='_blank' rel='noopener'>
                <Icon className={styles.socialIcon} icon={["fab", "telegram-plane"]} />
              </a>
              <a className={styles.socialItem} href='https://reddit.com/r/CamGirlsIndex' aria-label='Reddit' target='_blank' rel='noopener'>
                <Icon className={styles.socialIcon} icon={["fab", "reddit-alien"]} />
              </a>
              <a className={styles.socialItem} href='https://twitter.com/SloppyDeepCom' aria-label='Instagram' target='_blank' rel='noopener'>
                <Icon className={styles.socialIcon} icon={["fab", "instagram"]} />
              </a>
              <a className={styles.socialItem} href='https://discord.gg/ws9wqMJ' aria-label='Discord' target='_blank' rel='noopener'>
                <Icon className={styles.socialIcon} icon={["fab", "discord"]} />
              </a>
            </div>
            <ul className={styles.footerLinkList}>
              <li className={styles.footerLinkItem}>
                <Link to={'/legal/terms-of-service/'} onClick={onHamburgerClick}>Terms of Service</Link>
              </li>
              <li className={styles.footerLinkItem}>
                <Link to={'/legal/privacy-policy/'} onClick={onHamburgerClick}>Privacy Policy</Link>
              </li>
              <li className={styles.footerLinkItem}>
                <Link to={'/legal/dmca/'} onClick={onHamburgerClick}>2257</Link>
              </li>
              <li className={styles.footerLinkItem}>
                <Link to={'/legal/dmca/'} onClick={onHamburgerClick}>DMCA</Link>
              </li>
            </ul>
            <div className={styles.footerCopyright}>© 2020 SloppyDeep</div>
          </div>
        </nav>
      </section>
    </>
  )
}

export default Sidebar