import React from 'react'
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import WebcamSVG from 'assets/images/custom-icons/webcam.svg'

import styles from './Icon.module.scss';

export default ({ icon, className, toggleIcon, ...restProps }) => {
  let classes = classNames(
    styles.icon,
    { [styles.toggleIcon]: toggleIcon },
    className
  );

  return (
    <span className={classes} {...restProps} >
      { icon[0] === 'custom' && icon[1] === 'webcam' ? <WebcamSVG /> : <FontAwesomeIcon icon={icon} /> }
    </span>
  )
}