import React, { useState, useEffect } from 'react'

import 'styles/global.scss'
import 'lib/fontawesome'

import { SearchIndexContext, UserInfoContext } from 'utils/store'
import ClientOnly from 'utils/client-only.js'

import Header from 'components/Header'
import Sidebar from 'components/Sidebar'
import CookiesBar from 'components/CookiesBar'

const Base = (props) => {
  const [userInfo, setUserInfo] = useState(null)
  const [searchIndex, setSearchIndex] = useState({
    video: null,
    model: null,
  })
  const [showSidebar, setShowSidebar] = useState(false)

  const sidebarToggle = () => {
    setShowSidebar(!showSidebar);

    const bodyElement = document.querySelector('body');
    bodyElement.classList.toggle('lock-scroll');
  }

  useEffect(() => {
    fetch('https://ipapi.co/json/')
      .then(res => res.json())
      .then(data => setUserInfo(data))
      .catch(err => console.log(err));
  }, [])

  return (
    <>
      <ClientOnly>
        <CookiesBar />
      </ClientOnly>
      <Header
        onHamburgerClick={sidebarToggle}
      />
      <Sidebar
        shouldShow={showSidebar}
        onHamburgerClick={sidebarToggle}
        onOverlayClick={sidebarToggle}
      />

      <UserInfoContext.Provider value={userInfo}>
      <SearchIndexContext.Provider value={[ searchIndex, setSearchIndex ]}>
        {props.children}
      </SearchIndexContext.Provider>
      </UserInfoContext.Provider>
    </>
  );
}

export default Base