import { useState, useEffect } from 'react'

const ClientOnly = ({ children }) => {
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => setHasMounted(true),[])

  return hasMounted ? children : null;
}

export default ClientOnly;