// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-category-category-jsx": () => import("./../src/templates/Category/Category.jsx" /* webpackChunkName: "component---src-templates-category-category-jsx" */),
  "component---src-templates-category-list-alphabetically-categories-jsx": () => import("./../src/templates/CategoryList/AlphabeticallyCategories.jsx" /* webpackChunkName: "component---src-templates-category-list-alphabetically-categories-jsx" */),
  "component---src-templates-category-list-popular-categories-jsx": () => import("./../src/templates/CategoryList/PopularCategories.jsx" /* webpackChunkName: "component---src-templates-category-list-popular-categories-jsx" */),
  "component---src-templates-country-block-country-block-jsx": () => import("./../src/templates/CountryBlock/CountryBlock.jsx" /* webpackChunkName: "component---src-templates-country-block-country-block-jsx" */),
  "component---src-templates-home-home-jsx": () => import("./../src/templates/Home/Home.jsx" /* webpackChunkName: "component---src-templates-home-home-jsx" */),
  "component---src-templates-legal-dmca-jsx": () => import("./../src/templates/Legal/DMCA.jsx" /* webpackChunkName: "component---src-templates-legal-dmca-jsx" */),
  "component---src-templates-legal-privacy-policy-jsx": () => import("./../src/templates/Legal/PrivacyPolicy.jsx" /* webpackChunkName: "component---src-templates-legal-privacy-policy-jsx" */),
  "component---src-templates-legal-terms-of-service-jsx": () => import("./../src/templates/Legal/TermsOfService.jsx" /* webpackChunkName: "component---src-templates-legal-terms-of-service-jsx" */),
  "component---src-templates-model-list-latest-models-jsx": () => import("./../src/templates/ModelList/LatestModels.jsx" /* webpackChunkName: "component---src-templates-model-list-latest-models-jsx" */),
  "component---src-templates-model-list-popular-models-jsx": () => import("./../src/templates/ModelList/PopularModels.jsx" /* webpackChunkName: "component---src-templates-model-list-popular-models-jsx" */),
  "component---src-templates-model-model-jsx": () => import("./../src/templates/Model/Model.jsx" /* webpackChunkName: "component---src-templates-model-model-jsx" */),
  "component---src-templates-model-search-model-search-jsx": () => import("./../src/templates/ModelSearch/ModelSearch.jsx" /* webpackChunkName: "component---src-templates-model-search-model-search-jsx" */),
  "component---src-templates-video-list-latest-videos-jsx": () => import("./../src/templates/VideoList/LatestVideos.jsx" /* webpackChunkName: "component---src-templates-video-list-latest-videos-jsx" */),
  "component---src-templates-video-list-popular-videos-jsx": () => import("./../src/templates/VideoList/PopularVideos.jsx" /* webpackChunkName: "component---src-templates-video-list-popular-videos-jsx" */),
  "component---src-templates-video-search-video-search-jsx": () => import("./../src/templates/VideoSearch/VideoSearch.jsx" /* webpackChunkName: "component---src-templates-video-search-video-search-jsx" */),
  "component---src-templates-video-video-jsx": () => import("./../src/templates/Video/Video.jsx" /* webpackChunkName: "component---src-templates-video-video-jsx" */)
}

