import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import classNames from 'classnames'

import Icon from 'components/Icon'

import styles from './SearchBox.module.scss'

const SearchBox = ({ shouldShowOnMobile, onToggleClick }) => {
  const [query, setQuery] = useState('')
  const [filter, setFilter] = useState('videos')
  const [showFilterMenu, toggleFilterMenu] = useState(false)
  
  const handleFormSubmit = event => {
    event.preventDefault()
    if (query !== '') {
      navigate(`/${filter}/search?query=${query}`)
    } else {
      navigate(`/${filter}/`)
    }
  }

  const handleFilterMenuClose = () => {
    toggleFilterMenu(false)
    document.removeEventListener('click', handleFilterMenuClose)
  }

  useEffect(() => {
    if (showFilterMenu) {
      document.addEventListener('click', handleFilterMenuClose);
    }
  }, [showFilterMenu])
  
  const handleQueryChange = event => setQuery(event.target.value)

  const searchBoxClasses = classNames(styles.searchBox, { [styles.show]: shouldShowOnMobile })
  const filterButtonClasses = classNames(styles.button, styles.filterButton, { [styles.activeButton]: showFilterMenu })

  return (
    <div className={searchBoxClasses}>
      <button className={`${styles.toggle} ${styles.searchToggle}`} onClick={onToggleClick} aria-label='show searchbox'>
        <Icon icon={['fas', 'search']} toggleIcon />
      </button>
      <button className={`${styles.toggle} ${styles.arrowLeftToggle}`} onClick={onToggleClick} aria-label='hide searchbox'>
        <Icon icon={['fas', 'arrow-left']} toggleIcon />
      </button>
      <form onSubmit={handleFormSubmit} style={{ borderBottomLeftRadius: showFilterMenu ? '0' : null }}>

        <button type="button" className={filterButtonClasses} onClick={() => toggleFilterMenu(!showFilterMenu)} aria-label='filter'>
          <Icon icon={filter === 'videos' ? ['fas', 'video'] : ['custom', 'webcam']} />
          { showFilterMenu
            ? <Icon icon={['fas', 'caret-up']} className={styles.caretIcon} />
            : <Icon icon={['fas', 'caret-down']} className={styles.caretIcon} />
          }
        </button>

        { showFilterMenu &&
          <ul className={styles.filterMenu}>
            <li className={styles.filterItem} onClick={() => filter !== 'videos' ? setFilter('videos') : null } >
              <Icon icon={['fas', 'video']} className={styles.filterIcon} />
              Videos
            </li>
            <li className={styles.filterItem} onClick={() => filter !== 'models' ? setFilter('models') : null }>
              <Icon icon={['custom', 'webcam']} className={styles.filterIcon} />
              Models
            </li>
          </ul>
        }

        <input
          type='text'
          placeholder='Search...'
          className={styles.searchField}
          onChange={handleQueryChange}
          aria-label='search field'
        />
        <button type='submit' className={`${styles.button} ${styles.searchButton}`} aria-label='search'>
          <Icon icon={['fas', 'search']} />
        </button>
      </form>

    </div>
  )
}

export default SearchBox