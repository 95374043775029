import React, { useState } from 'react'
import { Link } from 'gatsby'
import { useCookies } from 'react-cookie'

import styles from './CookiesBar.module.scss'

const CookiesBar = () => {
  const [showBar, setShowBar] = useState(false)
  const [cookies, setCookie] = useCookies(['accept-cookies']);

  const handleCookiesAccept = () => {
    const expiresDate = new Date()
    expiresDate.setFullYear(expiresDate.getFullYear() + 1)

    setCookie('accept-cookies', 'true', {
      path: '/',
      expires: expiresDate
    })
    setShowBar(false)
  }

  if (cookies['accept-cookies'] === undefined && !showBar) {
    setShowBar(true)
  }

  if (!showBar) return null;

  return (
    <div className={styles.cookiesBar}>
      <span className={styles.text}>
        We use cookies to optimize site functionality and give you the best experience. <Link to={'/legal/privacy-policy/'}>Learn more</Link>
      </span>
      <button className={styles.button} onClick={handleCookiesAccept}>Got it</button>
    </div>
  )
}

export default CookiesBar