import React, { useState } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import classNames from 'classnames'

import Icon from 'components/Icon'
import SearchBox from './SearchBox'

import styles from './Header.module.scss'

const Header = (props) => {
  const data = useStaticQuery(graphql`
    {

      logo: file(
        relativeDirectory: {regex: "/"},
        name: {eq: "logoV3"}
      ) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

    }
  `)

  const { logo } = data

  const [showSearchBoxOnly, setShowSearchBoxOnly] = useState(false)

  const handleHamburgerClick = () => props.onHamburgerClick()
  const searchBoxToggle = () => setShowSearchBoxOnly(!showSearchBoxOnly)

  let headerClasses = classNames(styles.header, { [styles.centerOnly]: showSearchBoxOnly })

  return (
    <header className={headerClasses}>
      <div className={styles.left}>
        <button className={styles.hamburger} onClick={handleHamburgerClick} aria-label='toggle menu'>
          <Icon icon={['fas', 'bars']} toggleIcon />
        </button>

        <div className={styles.logoContainer}>
          <Link to='/' className={styles.logoContainer}>
            <Img fluid={logo.childImageSharp.fluid} className={styles.logoImg} alt='Sloppy Deep Logo' />
          </Link>
        </div>
        
      </div>

      <div className={styles.center} style={showSearchBoxOnly ? { flex: 1 } : null }>
        <SearchBox shouldShowOnMobile={showSearchBoxOnly} onToggleClick={searchBoxToggle} />
      </div>

      <div className={styles.right}></div>
    </header>
  )
}

export default Header